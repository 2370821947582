import type { Schema } from '@biteinc/common';
import { CreateUserType, regexStringFromEnum } from '@biteinc/enums';

export const createUsersSchema: Schema.Model = {
  displayName: 'user',
  type: 'object',
  fields: {
    type: {
      type: 'keyString',
      displayName: 'User Type',
      required: true,
      isHidden: true,
      validationRegex: regexStringFromEnum<CreateUserType>(CreateUserType),
    },
    usernames: {
      type: 'longString',
      displayName: 'Emails',
      required: true,
      tooltip: {
        text: 'Create multiple users by entering email addresses on separate lines or separated by commas.',
      },
      conditions: [
        {
          field: 'type',
          values: [CreateUserType.BiteAdminPortal],
        },
      ],
    },
    username: {
      type: 'email',
      displayName: 'Email',
      required: true,
      conditions: [
        {
          field: 'type',
          values: [CreateUserType.InStore],
        },
      ],
    },
    accessCode: {
      type: 'keyString',
      validationRegex: '^[0-9]{4}$',
      displayName: 'Access Code',
      conditions: [
        {
          field: 'type',
          values: [CreateUserType.InStore],
        },
      ],
    },
    locationGroupId: {
      type: 'mongoId',
      displayName: 'Location Access',
      required: true,
      ui: 'dropdown',
    },
    userRoleId: {
      type: 'mongoId',
      displayName: 'Role',
      required: true,
      ui: 'dropdown',
    },
  },
};
