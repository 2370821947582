import _ from 'underscore';

import { Log } from '@biteinc/common';

import { TimeHelper } from '../../helpers/time_helper';

app.QuicksandRecordListView = app.PaginatedListView.extend({
  initialize(options, ...args) {
    app.PaginatedListView.prototype.initialize.apply(this, [
      {
        canCollapse: true,
        showTimestampsInLocationTimezone: true,
        searchEnabled: true,
        isLocalSearch: false,
        filterSchemaButton: false,
        filterSchema: {
          fields: {
            fromTimestamp: {
              type: 'timestamp',
              displayName: 'From',
              allowFutureDates: false,
              allowPastDates: true,
              useSiteTimezone: true,
            },
            toTimestamp: {
              type: 'timestamp',
              displayName: 'To',
              allowFutureDates: false,
              allowPastDates: true,
              useSiteTimezone: true,
            },
            syncedBy: {
              type: 'shortString',
              displayName: 'Synced By',
              tooltip: {
                text: '"automatic", "webhook", "manual", or a Bite username',
              },
            },
          },
        },
        ...options,
      },
      ...args,
    ]);
  },

  _exportQuicksandRecords() {
    // Reliably start the download and allows us to set the file name.
    const aLink = document.createElement('a');
    aLink.download = `pos-sync-records-${app.location.id}.csv`;
    const lines = [
      [
        'Record Type',
        'Model Type',
        'Model Name',
        'Model ID',
        'Sync Type',
        'Sync Time',
        'Difference (Updates Only)',
      ],
    ];

    this.collection.models.forEach((model) => {
      lines.push(model.toCsv());
    });
    const data = lines.join('\n');
    const csvContent = 'data:text/csv;charset=utf-8,';

    aLink.href = encodeURI(csvContent + data);
    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  },

  render() {
    app.PaginatedListView.prototype.render.apply(this, arguments);

    const $syncButton = $(
      '<button class="btn btn-sm btn-primary" type="button">' +
        '<i class="bi bi-arrow-clockwise"></i>&nbsp;Sync Menu' +
        '</button>',
    );
    this.$('.card-header .right-button-container').append($syncButton);

    const latestSyncedAt = _.reduce(
      app.integrationList.models,
      (max, i9n) => {
        const syncedAt = i9n.getI9nSchema().type === 'pos' && i9n.get('syncInfo')?.syncedAt;
        return Math.max(max, syncedAt || 0);
      },
      0,
    );
    if (latestSyncedAt > 0) {
      this.$('.card-header .right-button-container').append(
        `<div class="info">Last synced at: ${TimeHelper.displayDateFromTimestamp(
          latestSyncedAt,
          app.location.get('timezone'),
        )}</div>`,
      );
    }

    if (app.sessionUser.canManageMenu()) {
      $syncButton.click(() => {
        $syncButton.prop('disabled', true);
        app.location.syncMenu((err, getJobResults) => {
          $syncButton.prop('disabled', false);

          if (err) {
            Log.error('sync menu err', err);
            return;
          }

          if (getJobResults) {
            const getJobResultsWithMessages = _.filter(getJobResults, (getJobResult) => {
              return !!getJobResult.message;
            });
            const messages = _.map(getJobResultsWithMessages, (getJobResult) => {
              return getJobResult.message;
            });

            if (messages.length) {
              const message = messages.join('\n\n');
              new app.AlertView().show(message, null, () => {
                window.location.reload();
              });

              return;
            }
          }

          setTimeout(() => {
            window.location.reload();
          }, 1500);
        });
      });
    } else {
      $syncButton.prop('disabled', true);
    }

    this.$searchHint.html('Search for Update Records using Model Name, Bite ID, or POS ID');
    this.$searchField.on('focus', () => {
      this.$searchHint.slideDown();
    });

    const $downloadButton = $(
      '<button type="button" class="btn btn-sm btn-primary download">Download as CSV</button>',
    );
    this.$('.card-header .right-button-container').prepend($downloadButton);
    $downloadButton.click(() => {
      this._exportQuicksandRecords();
    });

    return this;
  },
});
