app.InternalToolsView = app.BaseToolsView.extend({
  _refreshDayPartsClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton(
      $button.html(),
      'Making Request',
      'Refresh in Progress',
    );
    const url = '/api/v2/internal-tools/refresh-day-parts';
    app.makeRequest(
      'POST',
      url,
      null,
      () => {
        $button.loadingDidFinishSuccessfully(reqId);
      },
      () => {
        $button.loadingDidFinishWithError(reqId);
      },
    );
  },

  _syncEsperDevicesClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Syncing...', 'Synced!');
    const url = '/api/v2/internal-tools/sync-esper-devices';
    app.QueueHelper.makeRequest('POST', url, null, true, (err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
      }
    });
  },

  _intentionallyCrashClicked() {
    throw new Error('Intentional crash');
  },

  render() {
    app.BaseToolsView.prototype.render.apply(this, arguments);

    if (app.sessionUser.isBiteOps()) {
      this.addToolbarButton(
        'btn-primary',
        'Refresh Day Parts ⚒',
        this._refreshDayPartsClicked.bind(this),
      );

      this.addToolbarButton(
        'btn-primary',
        'Sync Esper Devices ⚒',
        this._syncEsperDevicesClicked.bind(this),
      );
    }
    if (app.sessionUser.isBiteEng()) {
      this.addToolbarButton(
        'btn-primary',
        'Intentionally Crash ⚙',
        this._intentionallyCrashClicked.bind(this),
      );
    }

    return this;
  },
});
