import { RecommendationRuleEffectName } from '@biteinc/enums';

app.RecommendationRuleDetailsView = app.BaseDetailsView.extend({
  _updateModifierFields() {
    const effectName = this.fieldGroupView.fieldViewsByField.effectName.getValue();
    if (effectName === RecommendationRuleEffectName.RecommendModifiers) {
      const itemList = this.fieldGroupView.fieldViewsByField.conditionSchemaItemIds.getValue();
      if (!itemList) {
        return;
      }

      const list = app.modList.filter((mod) => {
        return itemList.every((itemId) => {
          return app.menuItemList.get(itemId).hasModWithId(mod.id);
        });
      });
      const listOfModIds = list.map((mod) => mod.id);

      this.fieldGroupView.fieldViewsByField.effectModifierIds.collection.set(list);

      // trim the list of selected modifiers down to only the modifiers
      // that are available for the selected items
      let selectedModIdList =
        this.fieldGroupView.fieldViewsByField.effectModifierIds.getValue() || [];
      if (selectedModIdList.length) {
        selectedModIdList = selectedModIdList.filter((modId) => listOfModIds.includes(modId));
      }

      this.fieldGroupView.fieldViewsByField.effectModifierIds.list = selectedModIdList;
      this.fieldGroupView.fieldViewsByField.effectModifierIds._renderList();
    }
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    // add some spacing around these two distinct concepts
    const $conditionLabel = this.$el.find("label:contains('Condition:')");
    $conditionLabel.parent().prepend('<br /><hr />');

    const $effectLabel = this.$el.find("label:contains('Effect:')");
    $effectLabel.parent().prepend('<br /><hr />');

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.effectName,
      app.FieldView.Events.FieldDidChangeValue,
      this._updateModifierFields,
    );

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.effectItemIds,
      app.FieldView.Events.FieldDidChangeValue,
      this._updateModifierFields,
    );

    this._updateModifierFields();

    return this;
  },
  __fieldGroupDidChangeValue() {
    app.BaseDetailsView.prototype.__fieldGroupDidChangeValue.apply(this, arguments);

    this._updateModifierFields();
  },
});
