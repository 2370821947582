import _ from 'underscore';

import { ModelType, OrderSearchToken } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';

import { TimeHelper } from '../../helpers/time_helper';

app.OrderListView = app.PaginatedListView.extend({
  initialize(opts) {
    const options = {
      title: 'Orders',
      subtitle: `All times are in ${app.location.getTimezoneAbbr()}`,
      cellView: app.OrderCellView,
      searchEnabled: true,
      isLocalSearch: false,
      showTimestampsInLocationTimezone: true,
      filterSchemaButton: false,
      filterSchema: {
        fields: {
          fromTimestamp: {
            type: 'timestamp',
            displayName: 'From',
            allowFutureDates: false,
            allowPastDates: true,
            useSiteTimezone: !!app.site,
          },
          toTimestamp: {
            type: 'timestamp',
            displayName: 'To',
            allowFutureDates: false,
            allowPastDates: true,
            useSiteTimezone: !!app.site,
          },
        },
      },
      ...(opts || {}),
    };
    app.PaginatedListView.prototype.initialize.apply(this, [options]);

    if (this.options.guestId) {
      this._guest = new app.Guest();
      this._guest.id = this.options.guestId;
      this.listenToOnce(this._guest, 'change', this.render);
      this._guest.fetch();
    }

    this.listenTo(app, app.Event.ModelIdWasClicked, this._modelIdWasClicked);
  },

  createCell(model) {
    const cell = app.PaginatedListView.prototype.createCell.apply(this, [model]);
    cell.options.guestId = this.options.guestId;
    this.listenTo(
      cell,
      app.OrderCellView.Events.UserDidSelectTransactions,
      this._userDidSelectTransactions,
    );
    this.listenTo(
      cell,
      app.OrderCellView.Events.UserDidSelectSystemLogs,
      this._userDidSelectSystemLogs,
    );
    return cell;
  },

  _modelIdWasClicked(event) {
    if (event.parentView === this) {
      const modelType = parseInt(event.$targetView.attr('model-type'), 10);
      if (ModelType.Guest === modelType) {
        const guestId = event.$targetView.attr('model-id');
        const guestOrderList = new app.OrderList([], {
          url: `/api/v2/guests/${guestId}/orders`,
        });
        const guestOrderListView = new app.OrderListView({
          collection: guestOrderList,
          canBeClosed: !!this.options.tabView,
          title: `Orders for Guest ${guestId}`,
          guestId,
        });
        this.showView(guestOrderListView);

        event.wasHandled = true;
      }
    }
  },

  _userDidSelectTransactions(cell) {
    const order = cell.model;
    const transactionList = new app.TransactionList([], {
      url: `${order.url()}/transactions`,
    });
    const transactionListView = new app.PaginatedListView({
      collection: transactionList,
      searchEnabled: false,
      canBeClosed: true,
      cellView: app.TransactionCellView,
      cellOptions: {
        order,
      },
      showTimestampsInLocationTimezone: true,
      title: `Transactions for Order from ${TimeHelper.displayDateFromTimestamp(
        order.get('createdAt'),
        app.location.get('timezone'),
      )}`,
    });
    this.showView(transactionListView);
  },

  _userDidSelectSystemLogs(cell) {
    const order = cell.model;
    const biteLogList = new app.BiteLogList([], {
      url: `${app.BiteLogList.prototype.url()}?orderId=${order.id}`,
    });
    const biteLogListView = new app.BiteLogListView({
      collection: biteLogList,
      searchEnabled: false,
      canBeClosed: true,
      title: `System Logs for Order from ${TimeHelper.displayDateFromTimestamp(
        order.get('createdAt'),
        app.location.get('timezone'),
      )}`,
      filterSchemaButton: false,
      filterSchema: null,
      paginateToLoadSelectedItem: false,
    });
    this.showView(biteLogListView);
  },

  __navigateToModel(order) {
    this.scrollToItemWithId(order.id, true);
  },

  _exportMenu() {
    // Reliably start the download and allows us to set the file name.
    const aLink = document.createElement('a');
    aLink.download = 'orders.csv';
    const lines = [];
    _.each(this.collection.models, (order) => {
      lines.push(...order.toCsv());
      lines.push([]);
    });
    const data = lines.join('\n');
    const csvContent = 'data:text/csv;charset=utf-8,';

    aLink.href = encodeURI(csvContent + data);
    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  },

  render() {
    app.PaginatedListView.prototype.render.apply(this, arguments);

    app.HtmlHelper.renderSearchHints(
      'Search for orders using the Authorization number on the receipt, order number, email address, phone number, last four digits of the card, transaction reference number, gateway transactionId, kiosk ID, kiosk name (case insensitive), or orderId.',
      {
        'Order Flag': [
          OrderSearchToken.IsUnclosedOrUnfinished,
          OrderSearchToken.IsAbandoned,
          OrderSearchToken.IsUnsent,
          OrderSearchToken.IsFuture,
          OrderSearchToken.HasRefunds,
          OrderSearchToken.HasFailedToSendToPos,
          OrderSearchToken.HasStoredValue,
          OrderSearchToken.HasDiscount,
          OrderSearchToken.HasLoyaltyRedemption,
        ],
        'Fulfillment Method': [
          OrderSearchToken.IsToGo,
          OrderSearchToken.IsDineIn,
          OrderSearchToken.IsCurbside,
          OrderSearchToken.IsOutpost,
          OrderSearchToken.IsDelivery,
        ],
      },
      `${OrderSearchToken.HasRefunds} for all orders with a refund`,
      this.$searchHint,
      this.$searchField,
      this.$searchButton,
      'search',
    );

    const $downloadButton = $(
      '<button type="button" class="btn btn-sm btn-primary download">Download as CSV</button>',
    );
    this.$('.card-header .right-button-container').append($downloadButton);
    $downloadButton.click(() => {
      this._exportMenu();
    });

    if (this.options.guestId) {
      if (this._guest.isEmpty()) {
        this.$('.list-header').html('<div class="loading-pane">Loading Guest Data</div>');
      } else {
        const phoneNumber = this._guest.get('phoneNumber')
          ? `Phone Number: ${StringHelper.toFormattedPhoneNumber(this._guest.get('phoneNumber'))}\n`
          : '';
        this.$('.list-header').html(
          // prettier-ignore
          `<div class="container">
            <pre alt="guest-data">${phoneNumber}${this._guest.getDemographicSummary()}</pre>
          </div>`,
        );
      }
    }

    return this;
  },
});
