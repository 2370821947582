import _ from 'underscore';

import { IntegrationHelper } from '@biteinc/business-rules';
import { I9nSchemaBySystem } from '@biteinc/common';
import { IntegrationSystem, LoyaltyAuthMethod, OrderChannelHelper } from '@biteinc/enums';

import { TimeHelper } from '../../helpers/time_helper';

app.IntegrationDetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    /** @type IntegrationSystem */
    const system = this.model.get('system');
    const i9nSchema = I9nSchemaBySystem[system];
    if (
      i9nSchema.type === 'kiosk-payment' &&
      i9nSchema.apiPayment?.canFetchListOfTerminals &&
      app.sessionUser.isBite() &&
      !this.model.isNew()
    ) {
      this._paymentTerminalList = new app.PaymentTerminalList();
      this._paymentTerminalListView = new app.BaseListView({
        collection: this._paymentTerminalList,
        canCreate: false,
        searchEnabled: false,
      });
    }
  },

  getSchema() {
    const schema = app.JsonHelper.deepClone(app.BaseDetailsView.prototype.getSchema.apply(this));
    /** @type import('@biteinc/enums').IntegrationSystem */
    const system = this.model.get('system');
    const fields = {};
    const i9nSchema = I9nSchemaBySystem[system];
    _.each(i9nSchema.fields, (fieldSchema, fieldName) => {
      if (fieldName.startsWith('flash') && !app.location.get('flashEnabled')) {
        return;
      }
      if (!fieldSchema.requiresUserInput) {
        if (i9nSchema.type === 'loyalty' && fieldName === 'authMethods') {
          const supportedAuthMethods = OrderChannelHelper.isKiosk(app.location.get('orderChannel'))
            ? i9nSchema.supportedAuthMethods
            : // Web channels obviously can't use a barcode
              i9nSchema.supportedAuthMethods.filter(
                (authMethod) => authMethod !== LoyaltyAuthMethod.Barcode,
              );
          const defaultAuthMethods = [supportedAuthMethods[0]];
          if (defaultAuthMethods[0] === LoyaltyAuthMethod.Barcode) {
            const backupAuthMethod = supportedAuthMethods.find((authMethod) => {
              return authMethod !== LoyaltyAuthMethod.Barcode;
            });
            if (backupAuthMethod) {
              defaultAuthMethods.push(backupAuthMethod);
            }
          }
          fields[fieldName] = {
            ...fieldSchema,
            defaultValue: defaultAuthMethods,
          };
        } else {
          fields[fieldName] = fieldSchema;
        }
      }
    });
    const editableFields = IntegrationHelper.getGenericFieldsForSystem(system);
    editableFields.forEach((field) => {
      if (field === 'vendorId' && !app.location.supportsMultipleVendors()) {
        return;
      }
      fields[field] = schema.fields[field];
      if (field === 'diningOptions') {
        fields[field] = {
          ...fields[field],
          ...(i9nSchema.diningOptionConditions && {
            conditions: i9nSchema.diningOptionConditions,
          }),
        };
      } else if (field === 'loyaltyDiscountId' && system === IntegrationSystem.NcrAloha) {
        // Aloha integration currently requires a non-syncable, manual entry for the loyalty discount ID
        fields[field] = {
          ...fields[field],
          ui: undefined,
          tooltip: {
            text: 'This should be the comp ID (issued by the store), required to use for loyalty discounts.',
          },
        };
      }
    });

    schema.fields = fields;
    return schema;
  },

  getTitle() {
    const { name } = I9nSchemaBySystem[this.model.get('system')];
    return `${this.model.isNew() ? 'Create a New' : 'Edit'} ${name} Integration`;
  },

  getDeleteConfirmation() {
    return 'Are you sure you want to disconnect this integration?';
  },

  _didDestroyModel() {
    const { name } = I9nSchemaBySystem[this.model.get('system')];
    app.showSavedToastAndReload(
      `Disconnected from ${name}!`,
      this.model.getI9nSchema().type === 'pos',
    );
  },

  _didSaveModel() {
    const i9n = this.model;
    const isPos = i9n.getI9nSchema().type === 'pos';
    if (app.location.hasIntegrationWithSystem(i9n.get('system'))) {
      const posFieldNames = [
        'syncSections',
        'preserveSectionItemOrder',
        'preserveItemModGroupOrder',
        'preserveModGroupModOrder',
        'modGroupModSortPriority',
        'revalidateOrderBeforeSending',
        'skipNonSyncOperations',
        'maxSendOrderConcurrency',
        'notifyUserOfOrderStatusUpdates',
        'useWebhooksForOrderStatuses',
        'sendOrderStatusCheckup',
      ];
      this.collection.each((otherI9n) => {
        if (otherI9n.id !== i9n.id && otherI9n.getI9nSchema().type === 'pos') {
          _.each(posFieldNames, (fieldName) => {
            otherI9n.set(fieldName, i9n.get(fieldName));
          });
        }
      });
    } else {
      const { name } = I9nSchemaBySystem[i9n.get('system')];
      app.showSavedToastAndReload(`Connected to ${name}!`, isPos);
    }
  },

  _getSyncInfoPanel(syncInfo) {
    if (!_.keys(syncInfo || {}).length) {
      return null;
    }

    const $syncPanel = $(
      '<div class="card panel-default integration-sync-panel">' +
        '<span class="synced-at"></span>' +
        '<span class="sync-summary"></span>' +
        '<ul class="sync-summary"></ul>' +
        '</div>',
    );

    let syncedAtText = 'Last synced on: ';
    if (syncInfo.syncedAt) {
      syncedAtText += TimeHelper.displayDateFromTimestamp(syncInfo.syncedAt);
    } else {
      syncedAtText += 'Never';
    }
    $syncPanel.find('span.synced-at').html(syncedAtText);

    if (!this.model.getI9nSchema().type === 'supplementary-menu-source') {
      // Supplementary menu sources don't store sync summaries
      let syncSummaryText = '';
      let syncSummaryHeader = 'Last sync summary:';
      const syncSummary = syncInfo.syncSummary;
      if (_.keys(syncSummary).length) {
        _.each(syncSummary, (value, key) => {
          syncSummaryText += `<li>${key}: `;
          if (key === 'warnings') {
            syncSummaryText += '<ul>';
            _.each(value || [], (warning) => {
              syncSummaryText += `<li>${warning}</li>`;
            });
            syncSummaryText += '</ul>';
          } else {
            syncSummaryText += value;
          }
          syncSummaryText += '</li>';
        });
        $syncPanel.find('ul.sync-summary').html(syncSummaryText);
      } else {
        syncSummaryHeader += ' no changes.';
        $syncPanel.find('ul.sync-summary').remove();
      }
      $syncPanel.find('span.sync-summary').text(syncSummaryHeader);
    }

    return $syncPanel;
  },

  _getSyncedDataPanel(syncedData) {
    if (!_.keys(syncedData || {}).length) {
      return null;
    }

    return $(`<pre alt="syncedData">${JSON.stringify(syncedData, null, 2)}</pre>`);
  },

  // Make sure we are not sending any fields that are userProvided
  _save(data, opts, $saveButton) {
    const schema = this.getSchema();
    _.each(data, (value, field) => {
      if (!schema.fields[field]) {
        delete data[field];
      }
    });

    if (app.location.isLive()) {
      const wouldBeIntegration = new app.Integration(data);
      const isDrasticallyDifferent = this.model.isForDifferentLocationThan(wouldBeIntegration);
      if (isDrasticallyDifferent) {
        const confirmed = app.promptUserToTypeInConfirmation(
          `Please type in "UPDATE" to confirm that you want to drastically change this integration in a live location.`,
          'UPDATE',
        );
        if (!confirmed) {
          if (opts.error) {
            opts.error();
          }
          return;
        }
      }
    }

    app.BaseDetailsView.prototype._save.apply(this, [data, opts, $saveButton]);
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    const $syncInfoPanel = this._getSyncInfoPanel(this.model.get('syncInfo'));
    if ($syncInfoPanel) {
      this.$el.append($syncInfoPanel);
    }

    const $syncedDataPanel = this._getSyncedDataPanel(this.model.get('syncedData'));
    if ($syncedDataPanel) {
      this.$el.append($syncedDataPanel);
    }

    if (this._paymentTerminalListView) {
      this.$el.append(this._paymentTerminalListView.render().$el);
    }

    return this;
  },
});
