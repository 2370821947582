import { MaitredActivityUpdateType, regexStringFromEnum } from '@biteinc/enums';

app.MaitredActivityListView = app.PaginatedListView.extend({
  initialize(opts) {
    const options = {
      cellView: app.MaitredActivityCellView,
      searchEnabled: false,
      fetchOnInit: false,
      showTimestampsInLocationTimezone: !!app.site,
      filterSchemaButton: true,
      filterSchema: {
        fields: {
          fromTimestamp: {
            type: 'timestamp',
            displayName: 'From',
            allowFutureDates: false,
            allowPastDates: true,
            useSiteTimezone: !!app.site,
          },
          toTimestamp: {
            type: 'timestamp',
            displayName: 'To',
            allowFutureDates: false,
            allowPastDates: true,
            useSiteTimezone: !!app.site,
          },
          activityBy: {
            type: 'shortString',
            displayName: 'Activity By',
            tooltip: {
              text: 'Username of the user that performed the activity',
            },
          },
          ...(app.location && {
            modelQuery: {
              type: 'keyString',
              displayName: 'Model',
              tooltip: {
                text: 'Model Name, Bite ID, or POS ID',
              },
            },
            updateType: {
              type: 'keyString',
              displayName: 'Update Type',
              validationRegex: regexStringFromEnum(MaitredActivityUpdateType),
              ui: 'dropdown',
              isNullable: true,
            },
          }),
          descriptionQuery: {
            type: 'shortString',
            displayName: 'Description',
            tooltip: {
              text: '"tip" for tipping events, etc',
            },
          },
        },
      },
      ...(opts || {}),
    };
    app.PaginatedListView.prototype.initialize.apply(this, [options]);
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'updateType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MaitredActivityUpdateType,
          nameGenerator: (updateType) => {
            switch (updateType) {
              case MaitredActivityUpdateType.Changeset:
                return 'Changeset';
              case MaitredActivityUpdateType.Clone:
                return 'Clone';
              case MaitredActivityUpdateType.EightySix:
                return '86ed or Un-86ed';
              case MaitredActivityUpdateType.MagicCopy:
                return 'Magic Copy or Precision Copy';
              default:
                throw new Error(`unrecognized update type: ${updateType}`);
            }
          },
        });
    }
    return null;
  },

  _exportQuicksandRecords() {
    // Reliably start the download and allows us to set the file name.
    const aLink = document.createElement('a');
    aLink.download = `activity-log-${app.location.id}.csv`;
    const lines = [
      [
        'Time',
        'User',
        'Activity Type',
        'Model Type',
        'Model Name',
        'Model ID',
        'Description',
        'Original (Updates Only)',
      ],
    ];

    this.collection.models.forEach((model) => {
      lines.push(model.toCsv());
    });
    const data = lines.join('\n');
    const csvContent = 'data:text/csv;charset=utf-8,';

    aLink.href = encodeURI(csvContent + data);
    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  },

  render() {
    app.PaginatedListView.prototype.render.apply(this, arguments);

    const $downloadButton = $(
      '<button type="button" class="btn btn-sm btn-primary download">Download as CSV</button>',
    );
    this.$('.card-header .right-button-container').prepend($downloadButton);
    $downloadButton.click(() => {
      this._exportQuicksandRecords();
    });

    return this;
  },
});
