import { LocationRules } from '@biteinc/business-rules';
import { LocationMultiPaymentSupport } from '@biteinc/enums';

import { CryptHelper } from '../../helpers/crypt_helper';

app.LocationDetailsView = app.BaseDetailsView.extend({
  getSchema() {
    let schema = app.BaseDetailsView.prototype.getSchema.apply(this);

    const location = this.model;
    if (location.isLive()) {
      schema = app.JsonHelper.deepClone(schema);
      delete schema.fields.showNutritionDemo;
    }
    if (!location.supportsPayingWithCash() && schema.fields.allowPayAtCashier) {
      schema.fields.allowPayAtCashier.isPermanent = true;
      schema.fields.allowPayAtCashier.tooltip = {
        text: 'Cash payments are not currently supported by the POS integration.',
      };
    }
    if (!location.supportsTips() && schema.fields.tipsLevel) {
      schema.fields.tipsLevel.isPermanent = true;
      schema.fields.tipsLevel.tooltip = {
        text: location.hasMultiPaymentSupport()
          ? 'Tips are not currently supported by the due to multi-payment support.'
          : 'Tips are not currently supported by the POS integration.',
      };
    }

    const posSystem = location.getPosI9nSchema()?.system;
    const ecommPaymentSystem = location.getEcommPaymentI9nSchema()?.system;
    const posWillSendReceipts = LocationRules.posWillSendGuestEmailReceipts(
      location.get('orderChannel'),
      posSystem,
      ecommPaymentSystem,
    );
    if (posWillSendReceipts) {
      [
        'emailReceiptsEnabled',
        'emailReceiptHeaderImg',
        'emailReceiptHeaderText',
        'emailReceiptFooterText',
      ].forEach((emailReceiptFieldName) => {
        if (!schema.fields[emailReceiptFieldName]) {
          return;
        }
        schema.fields[emailReceiptFieldName].isPermanent = true;
        schema.fields[emailReceiptFieldName].tooltip = {
          text: 'Email receipts are handled by the POS integration.',
        };
      });
    }

    return schema;
  },

  _getTabId() {
    return Object.values(this.getSchema().fields)[0].tabId;
  },

  _renderCustomTemplates() {
    if (!app.sessionUser.isBite()) {
      return;
    }

    const tabId = this._getTabId();
    const templatesToPrepend = [];
    if (['receipt-settings', 'sms-settings'].includes(tabId)) {
      templatesToPrepend.push(
        this.valueDisplayTemplate({
          label: 'Notes on Templates:',
          value: `
          The templates below can use the following tokens:
          <br />BRAND_NAME, GUEST_EMAIL, GUEST_NAME, GUEST_PHONE_NUMBER, LOCATION_NAME, LOCATION_PHONE_NUMBER, ORG_NAME, ORDER_NUMBER, ORDER_PICKUP_TIME, ORDER_TOTAL, ORDER_URL, ORDERED_AT, OUTPOST_NAME, VENDOR_PICKUP_INSTRUCTIONS.
          <br />Consult our client services or eng team for help in setting these up.`,
        }),
      );
    }
    if (templatesToPrepend.length) {
      const $displayGroup = $('<div class="field-group"></div>');
      templatesToPrepend.forEach((template) => {
        $displayGroup.append(template);
      });
      this.$el.prepend($displayGroup);
    }

    // append fields not included in location schema
    const templatesToAppend = [];
    if (tabId === 'bite-settings') {
      templatesToAppend.push(
        this.valueDisplayTemplate({
          label: 'Location ID:',
          value: this.model.id,
        }),
      );
      templatesToAppend.push(
        this.valueDisplayTemplate({
          label: 'Site ID:',
          value: this.model.get('siteId'),
        }),
      );
      templatesToAppend.push(
        this.valueDisplayTemplate({
          label: 'Org ID:',
          value: this.model.get('orgId'),
        }),
      );
      templatesToAppend.push(
        this.valueDisplayTemplate({
          label: 'Expo Printer Username:',
          value: `bite-${this.model.get('shortCode')}`,
        }),
      );
      templatesToAppend.push(
        this.valueDisplayTemplate({
          label: 'Expo Printer Password:',
          // The epson password field has a maximum of 30 characters
          value: CryptHelper.generateHash(this.model.id).substr(0, 30),
        }),
      );
    }
    if (tabId === 'flash-settings' && this.model.get('flashEnabled')) {
      if (this.model.get('multiPaymentSupport') === LocationMultiPaymentSupport.Enabled) {
        app.vendors.forEach((vendor) => {
          templatesToAppend.push(
            this.valueDisplayTemplate({
              label: `Stripe Email for ${vendor.displayName()}:`,
              value: this.model.getStripeEmail(vendor.id),
            }),
          );
        });
      } else {
        templatesToAppend.push(
          this.valueDisplayTemplate({
            label: 'Stripe Email:',
            value: this.model.getStripeEmail(),
          }),
        );
      }
    }
    if (templatesToAppend.length) {
      const $displayGroup = $('<div class="field-group"></div>');
      templatesToAppend.forEach((template) => {
        $displayGroup.append(template);
      });
      this.$el.append($displayGroup);
    }
  },

  // set the payment terminal text field based on payment i9n
  _updateKioskPaymentFields() {
    if (this._getTabId() !== 'kiosk-settings') {
      return;
    }

    const fieldViews = this.fieldGroupView.fieldViewsByField;
    const kioskPaymentI9nSchema = this.model.getKioskPaymentI9nSchema();
    const showIdleText = !!kioskPaymentI9nSchema?.canDisplayText;
    fieldViews.paymentTerminalIdleText.$el.toggle(showIdleText);
  },

  __fieldGroupDidChangeValue() {
    app.BaseDetailsView.prototype.__fieldGroupDidChangeValue.apply(this, arguments);

    this._updateKioskPaymentFields();
  },

  __saveButtonWasClicked(/* $saveButton */) {
    if (this._getTabId() === 'kiosk-settings') {
      const hadDelphiOn = !!this.model.get('usesDelphi');
      const hasDelphiOff = !this.fieldGroupView.getValue().usesDelphi;
      if (hadDelphiOn && hasDelphiOff) {
        const shouldProceed = confirm(
          'It looks like you are turning off Delphi (Facial Recognition). We will automatically restart all kiosks at this location in 3 minutes. Are you sure you want to continue?',
        );
        if (!shouldProceed) {
          return;
        }
      }
    }

    app.BaseDetailsView.prototype.__saveButtonWasClicked.apply(this, arguments);
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    this._updateKioskPaymentFields();

    this._renderCustomTemplates();

    return this;
  },
});
