import { I9nSchemaBySystem } from '@biteinc/common';

app.PaymentTerminal = app.AbstractModel.extend({
  ModelName: 'paymentTerminal',
  Schema: {
    displayName: 'payment terminal',
    fields: {
      name: {
        type: 'keyString',
        isReadOnly: true,
      },
      serialNumber: {
        displayName: 'Serial Number',
        type: 'keyString',
        isReadOnly: true,
      },
      inUse: {
        displayName: 'Is In Use?',
        type: 'bool',
        isReadOnly: true,
      },
    },
  },
  idAttribute: 'serialNumber',

  canBeDestroyed() {
    return app.integrationList.models.some((integration) => {
      return !!I9nSchemaBySystem[integration.get('system')].apiPayment?.canDeactivateTerminals;
    });
  },
});
