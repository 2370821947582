app.OrgToolsView = app.BaseToolsView.extend({
  _deleteOrgButtonWasClicked(e) {
    if (!window.confirm('Are you sure you want to delete this org?')) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Deleting', 'Deleted');
    app.org.destroy({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success(data) {
        $button.loadingDidFinishSuccessfully(reqId);
        const message = data.status || 'Deleted this org!';
        app.showSavedToastAndReload(message);
        setTimeout(() => {
          window.location = '/';
        }, 1500);
      },
    });
  },

  render() {
    app.BaseToolsView.prototype.render.apply(this, arguments);

    this.addToolbarButton(
      'btn-danger',
      'Delete This Org',
      this._deleteOrgButtonWasClicked.bind(this),
    );

    return this;
  },
});
