import _ from 'underscore';

import { template } from '../../template';

app.DropdownFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} dropdown-field-view`;
  },

  _dropdownTemplate: template(
    // prettier-ignore
    '<div class="btn-group item-dropdown" role="group">' +
      '<button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">' +
        '<span class="text"></span>' +
      '</button>' +
      '<ul class="dropdown-menu" role="menu"></ul>' +
    '</div>',
  ),

  _updateDropdown() {
    if (this.valueModel) {
      const displayName = this._getDisplayNameForElement(this.valueModel, true);
      this.$dropdownToggle.find('.text').text(displayName);
      if (this.valueModel.get('disclaimer')) {
        const disclaimer = this.valueModel.get('disclaimer').split('\n').join('<br>');
        this.$('.form-text').html(disclaimer);
        this.$el.toggleClass('has-disclaimer', true);
      } else {
        this.$el.toggleClass('has-disclaimer', false);
      }
    } else {
      this.$dropdownToggle.find('.text').html(`Select ${this.getDisplayName()}:`);
      this.$el.toggleClass('has-disclaimer', false);
    }
  },

  setValue(value, model) {
    this.initialValue = value;
    if (model) {
      this.model = model;
    }

    if (
      value === undefined &&
      _.has(this.schema, 'defaultValue') &&
      this.model &&
      this.model.isNew()
    ) {
      // Don't apply default values to existing models
      // eslint-disable-next-line no-param-reassign
      value = this.schema.defaultValue;
    }

    this.toggleClearButton(value);

    this.value = value;

    if (this.model) {
      this.valueModel = this.model.getFieldModelForValue(
        this.field,
        value,
        this.subProperty,
        this.keyModel,
      );

      if (this.isPermanent()) {
        this.$dropdownToggle.prop('disabled', true);
      }

      this._setupDropdown(
        this.model.getFieldCollection(this.field, this.subProperty, false, this.keyModel),
      );
    } else if (this.listView) {
      this.valueModel = null;
      this._updateDropdown();
    }
  },

  _setupDropdown(collection) {
    this._dropdown = this._dropdown || new app.Dropdown();
    this._dropdown.setupDropdown(this.$inputContainer, collection, {
      getDisplayName: this._getDisplayNameForElement.bind(this),
      onSelect: (object) => {
        const prevValue = this.value;
        this.valueModel = object;
        this.value = object.get('value') || object.id;
        this._updateDropdown();
        if (this.value !== prevValue) {
          this.toggleClearButton(this.value);
          this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
        }
      },
      onAdd() {},
    });
    this._updateDropdown();
  },

  setupListViewFilter(listView) {
    app.FieldView.prototype.setupListViewFilter.apply(this, [listView]);

    this._setupDropdown(
      listView.getFieldCollection(this.field, this.subProperty, false, this.keyModel),
    );
  },

  getValue() {
    if (this.value === 0) {
      return 0;
    }
    return this.value || null;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    const value = this.getValue();
    const isValid = !this.isRequired() || value !== null;
    this.setState(isValid ? this.states.INIT : this.states.ERROR);
    return isValid ? { isValid } : { isValid, invalidFieldNames: [] };
  },

  _getDisplayNameForElement(element, plainTextOnly) {
    if (this.model && this.model.displayNameForListFieldElement) {
      const displayName = this.model.displayNameForListFieldElement(
        this.field,
        element,
        this.subProperty,
        this.keyModel,
        plainTextOnly,
      );
      if (displayName) {
        return displayName;
      }
    }
    return element.displayName();
  },

  renderField() {
    this.$inputContainer.prepend(this._dropdownTemplate());
    this.$dropdownToggle = this.$('button.dropdown-toggle');
    // Disable flipping so that our dropdown always appears below the toggle button
    this.$dropdownToggle.attr(
      'data-bs-popper-config',
      JSON.stringify({
        placement: 'bottom',
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      }),
    );

    if (this.schema.isNullable) {
      this._$clearValueButton = $(`
        <button type="button" class="btn btn-secondary clear-button">
          <i class="bi bi-trash3" aria-hidden="true" alt="Clear value"></i>
        </button>`);
      this._$clearValueButton.click(() => {
        this.setValue(null);
        this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
      });
      this.$inputContainer.append(this._$clearValueButton);
      this.$inputContainer.addClass('has-clear-button');
    }

    // When the dropdown opens, make some room for it!
    // This is a very imperfect way to make sure we don't have to scroll a whole bunch to get to
    // some dropdown options. It just expands the space underneath it and pushes down the next field
    const $dropdownMenu = this.$('.dropdown-menu');
    this.$inputContainer.find('.item-dropdown')[0].addEventListener('shown.bs.dropdown', () => {
      this.$el.css({ paddingBottom: Math.ceil($dropdownMenu.outerHeight()) });
    });
    this.$inputContainer.find('.item-dropdown')[0].addEventListener('hide.bs.dropdown', () => {
      this.$el.css({ paddingBottom: 0 });
    });
  },
});
